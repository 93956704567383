<template>
  <v-row>
    <v-col md="12">
      <v-card class="box card-cambio">
        <h1>Dados Administrativos</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" md="4">
            <v-autocomplete label="Tipos de operação" :items="exchangeTypes" item-text="description" item-value="id"
              v-model="correspondent.administrative.exchangeType" return-object clearable dense outlined
              v-disabled-icon-focus>
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" md="2">
            <NumberField v-model="correspondent.administrative.commission" outlined dense label="Comissão(%)" suffix="%">
            </NumberField>
          </v-col>
          <v-col class="py-0" md="2">
            <NumberField v-model="correspondent.administrative.globalMinimumSpread" outlined dense
              label="Spread Mínimo Global(%)" suffix="%"></NumberField>
          </v-col>
          <v-col class="py-0" md="2">
            <NumberField v-model="correspondent.administrative.globalRecommendedSpread" outlined dense
              label="Spread Sugerido Global(%)" suffix="%"></NumberField>
          </v-col>
          <v-col class="py-0" md="2">
            <NumberField v-model="correspondent.administrative.globalMaximumSpread" outlined dense
              label="Spread Máximo Global(%)" suffix="%"></NumberField>
          </v-col>
          <v-col class="py-0" md="12">
            <v-autocomplete label="Bancos Câmbio Turismo" :items="getTourismBanks" item-text="fantasyName" item-value="id"
              v-model="correspondent.tourismBanks" clearable dense outlined multiple v-disabled-icon-focus return-object>
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" md="12">
            <v-autocomplete label="Bancos Remessa" :items="getRemittanceBanks" item-text="fantasyName" item-value="id"
              v-model="correspondent.remittanceBanks" clearable dense outlined multiple v-disabled-icon-focus
              return-object>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Apis
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";

// Models
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import ExchangeBankModel from "@/model/exchange-bank-model";

// Components
import NumberField from "@/components/comum/NumberField";

export default {
  name: "CorrespondentAdministrative",
  components: { NumberField },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
      exchangeTypes: [],
      banks: [],
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
  mounted() {
    this.getExchangeTypes();
    this.getBankList();
  },
  computed: {
    getRemittanceBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 2);
    },

    getTourismBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 1);
    }
  },
  methods: {
    getExchangeTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
  }
};
</script>

<style></style>
