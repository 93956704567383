import api from '../api';

function add(spread, correspondentId) {
  spread = spread || {};
  return new Promise((resolve, reject) => {
    return api.post(`/correspondents/${correspondentId}/spreads`, spread)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(spread, correspondentId) {
  spread = spread || {};
  return new Promise((resolve, reject) => {
    return api.put(`/correspondents/${correspondentId}/spreads/${spread.id}`, spread)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByCorrespondent(correspondentId) {
  return new Promise((resolve, reject) => {
    return api.get(`/correspondents/${correspondentId}/spreads`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findByCorrespondent,
  add,
  update,
};