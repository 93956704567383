import dateUtils from './date';
import moneyUtils from './money';

function getCustomerMessage(operation) {
  // console.log(operation)
  //TODO ver banco
  return `
*Simulação de operação - ME*
${ dateUtils.maskDateAndHourIso(new Date()) }

Tipo Operação -  ${ operation.exchangeBuy ? 'Compra / Entrada' : 'Venda / Saída' }
Moeda         -  ${ operation.currency.name }
Nat. Operação -  ${ operation.nature.name }

VALOR ME      -  ${ moneyUtils.formatCurrency(operation.currency.code, operation.amount) }
Tx. de Câmbio -  ${ moneyUtils.formatCurrency('BRL', operation.customerRate, 6) }
IOF (${operation.iof}%)   -  ${moneyUtils.formatCurrency('BRL', operation.iofValue) }
I.R (${operation.ir}%)   -  ${moneyUtils.formatCurrency('BRL', operation.irValue) }
Tx. Remessa   -  ${ moneyUtils.formatCurrency('BRL', operation.exchangeContractCost) }
V.E.T.        -  ${ moneyUtils.formatCurrency('BRL', operation.vet, 4) }

TOTAL                     ${ moneyUtils.formatCurrency('BRL', operation.totalValue) }

*Cotação válida nos próximos 5 minutos*
*Sujeito a variação de acordo com o mercado*

_Seguem os dados bancário para realização do Pagamento_

A TED deve sair obrigatoriamente da conta do titular da operação. 

Banco..: 077 - BANCO INTER S/A  
CNPJ: 10.409.481/0001-47

Agência: 0001
Conta..: 4278838 - 2
Favorecido: BH TEC SERVIÇOS LTDA

*Favor nos enviar o comprovante assim que realizada.*

*AGUARDO SUAS INSTRUÇÕES PARA O FECHAMENTO JUNTO AO BANCO* 
`;
}

function getBankMessage(operation) {
  // console.log(operation)
  //TODO ver canal bancário
  return `
*INFORMAR COTAÇÃO*                               ${ dateUtils.maskDateAndHourIso(operation.updatedAt) }

CLIENTE      - ${ operation.customer.name }
CPF/CNPJ     - ${ operation.customer.cpfCnpj }

TIPO DA OP   - ${ operation.exchangeBuy ? 'Compra / Entrada' : 'Venda / Saída' }
MOEDA        - ${ operation.currency.code } - ${ operation.currency.name }
VALOR ME     - ${ moneyUtils.formatCurrency(operation.currency.code, operation.amount) }
DESPESA      - ${ moneyUtils.formatCurrency('BRL', operation.exchangeContractCost) }
I.R (${operation.ir}%)  - ${moneyUtils.formatCurrency('BRL', operation.irValue) }
NATUREZA     - ${ operation.nature.name }
LIQUIDAÇÃO   - ${ operation.liquidation.description }
TAXA CLIENTE - ${ moneyUtils.formatCurrency('BRL', operation.customerRate, 6) }

*TOTAL:  ${ moneyUtils.formatCurrency('BRL', operation.totalValue) }* 

CANAL BANCÁRIO
NOME DO RECEBEDOR - THAIS MARTINEZ
BANCO - BANK OF AMERICA
SWIFT - BOFAUS3N
CONTA - 8980 7709 4989
ROUTING/ABA - 026009593
`;
}

export default {
  getCustomerMessage,
  getBankMessage,
};