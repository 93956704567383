<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Emails Operacionais</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" cols="12" md="12">
            <h2>Cadastro</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Primeiro Email" v-model="correspondent.firstRegisterEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Segundo Email" v-model="correspondent.secondRegisterEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Terceiro Email" v-model="correspondent.thirdRegisterEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Operação</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Primeiro Email" v-model="correspondent.firstOperationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Segundo Email" v-model="correspondent.secondOperationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Terceiro Email" v-model="correspondent.thirdOperationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Documentação</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Primeiro Email" v-model="correspondent.firstDocumentationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Segundo Email" v-model="correspondent.secondDocumentationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Terceiro Email" v-model="correspondent.thirdDocumentationEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Financeiro</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Primeiro Email" v-model="correspondent.firstFinancialEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Segundo Email" v-model="correspondent.secondFinancialEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <MailField label="Terceiro Email" v-model="correspondent.thirdFinancialEmail"></MailField>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

// Components
import MailField from "@/components/comum/MailField";

export default {
  name: "CorrespondentContactEmail",
  components: { MailField },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
};
</script>

<style></style>
