/**
 * Formats a number to the brazilian currency format
 * @param {String} currency The currency code
 * @param {Number} value The value
 * @returns {String} The formated value
 */
function formatCurrency(currency, value, precision) {
  if (!value) {
    value = 0;
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: precision,
  });

  return formatter.format(value);
}

export default {
  formatCurrency,
};
