<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Dados Bancarios</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" cols="12" md="2">
            <v-checkbox label="Utiliza conta de pagamento" v-model="bank.paymentAccount" dense></v-checkbox>
          </v-col>
          <template v-if="bank.paymentAccount">
            <v-col class="py-0" cols="12" md="3">
              <v-autocomplete label="Banco vinculado" :item-text="itemTextBank" clearable item-value="code" dense outlined
                hint="Este campo é obrigatório" v-disabled-icon-focus v-model="bank.linkedBank" :items="listOfBanks">
              </v-autocomplete>
            </v-col>
          </template>
          <template v-else>
            <v-col class="py-0" cols="12" md="3">
              <v-autocomplete label="Banco" :item-text="itemTextBank" clearable item-value="code" dense outlined
                hint="Este campo é obrigatório" v-disabled-icon-focus v-model="bank.bank" :items="listOfBanks">
              </v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="12" md="2">
              <v-text-field v-model="bank.agency" outlined dense label="Agência"></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="2">
              <v-text-field v-model="bank.account" outlined dense label="Conta"></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="3">
              <v-text-field v-model="bank.pix" outlined dense label="PIX"></v-text-field>
            </v-col>
          </template>
          <v-col class="py-0" cols="12" md="2">
            <legend>Usa limite IN-Receber/OUT-Enviar?</legend>
            <v-radio-group row v-model="bank.useInOutLimit" class="py-0" dense>
              <v-radio :value="true" label="SIM"></v-radio>
              <v-radio :value="false" label="NÃO"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <legend>Limite em qual moeda?</legend>
            <v-radio-group row v-model="bank.useUsdLimit" class="py-0" dense>
              <v-radio :value="true" label="Dólar Americano - USD"></v-radio>
              <v-radio :value="false" label="Real - BRL"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <legend>Contrato de câmbio em qual moeda?</legend>
            <v-radio-group row v-model="bank.useUsdExchangeContract" class="py-0" dense>
              <v-radio :value="true" label="Dólar Americano - USD"></v-radio>
              <v-radio :value="false" label="Real - BRL"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <MoneyField label="Custo de contrato de câmbio" v-model="bank.exchangeContractCost"
              :prefix="bank.useUsdExchangeContract ? '$ ' : 'R$ '" />
          </v-col>
          <v-col class="py-0" cols="12" md="1">
            <NumberField v-model="bank.commission" outlined dense label="Comissão(%)" suffix="%"></NumberField>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Api
import apiBanks from "@/api/general/bank-api";

// Components
import MoneyField from "@/components/comum/MoneyField";
import NumberField from "@/components/comum/NumberField";

export default {
  name: "BankFinancial",
  components: { MoneyField, NumberField },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
      listOfBanks: [],
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
  },
  mounted() {
    this.getBankList();
  },
  computed: {
    itemTextBank() {
      return (item) => !item.code ? '000 - ' + item.name.toUpperCase() : item.code + ' - ' + item.name.toUpperCase();
    }
  },
  methods: {
    getBankList() {
      apiBanks
        .getBanks()
        .then((response) => {
          this.listOfBanks = response.data.reduce((accumulator, current) => {
            if (current.name == undefined || !current.code) {
              return accumulator;
            }
            accumulator.push(current);
            return accumulator;
          }, []);

          this.bank.linkedBank = this.listOfBanks.find(b => b.code == this.bank.linkedBank);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
};
</script>

<style></style>
