<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="heading" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-divider></v-divider>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" v-if="!editMode">
        <v-card class="box card-cambio">
          <h1>Tipo de formulário</h1>
          <v-divider></v-divider>
          <v-spacer class="py-2"></v-spacer>
          <v-radio-group v-model="correspondent.correspondentPersonType.id" row>
            <v-radio v-for="(item, i) in listOfCorrespondentPersonTypes" :key="i" :value="item.id"
              :label="item.description"></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>
      <v-col md="12" v-if="correspondent.correspondentPersonType.id">
        <v-form>
          <div>
            <CorrespondentCover v-model="correspondent" :isInternationalAgent="isInternationalAgent" :isPj="isPj" />
            <CorrespondentFinancial v-model="correspondent" />
            <CorrespondentPartnersShareholders v-model="correspondent" v-if="isPj" />
            <CorrespondentContactEmail v-model="correspondent" />
            <CorrespondentAdministrative v-model="correspondent" v-if="isAdministrativeAccess && !isIndicated" />
            <CorrespondentSpreads v-model="correspondent" v-if="isAdministrativeAccess && !isIndicated" />
            <CorrespondentIndicated v-model="correspondent" v-if="isAdministrativeAccess && !isIndicated && editMode" />
          </div>
        </v-form>
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col cols="12" md="12">
            <v-btn @click="cancelCorrespondentCreation" class="btn-tertiary float-right" text>Cancelar</v-btn>
            <v-btn class="btn-primary mx-2 float-right" @click="!editMode ? save() : update()" text>Salvar</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-dialog v-model="userDialog" persistent scrollable max-width="800px">
        <v-card flat>
          <v-card-title>
            <h1 class="titulo-modal">
              Dados de Acesso
            </h1>
          </v-card-title>

          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <v-form validation v-model="userFormValid">
                <v-row class="mt-2">
                  <v-col md="6">
                    <MailField v-model="user.email" label="E-mail" :rules="[validationIsRequiredField]">
                    </MailField>
                  </v-col>
                  <v-col md="6">
                    <v-text-field label="Senha" v-model="user.password" outlined
                      :append-icon="passwordVisible ? 'visibility_off' : 'visibility'"
                      @click:append="() => (passwordVisible = !passwordVisible)"
                      :type="passwordVisible ? 'text' : 'password'" :rules="[validationPasswordField]"
                      dense></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn text class="btn-primary mr-2" :disabled="!userFormValid" @click="saveUser">
                Salvar
              </v-btn>
              <v-btn text class="btn-tertiary" @click="userDialog = false"> Cancelar </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
// Components
import CorrespondentCover from "../../components/form-correspondent/CorrespondentCover.vue";
import CorrespondentFinancial from "../../components/form-correspondent/CorrespondentFinancial.vue";
import CorrespondentContactEmail from "../../components/form-correspondent/CorrespondentContactEmail.vue";
import CorrespondentAdministrative from "../../components/form-correspondent/CorrespondentAdministrative.vue";
import CorrespondentPartnersShareholders from "../../components/form-correspondent/CorrespondentPartnersShareholders.vue";
import CorrespondentSpreads from "../../components/form-correspondent/CorrespondentSpreads.vue";
import CorrespondentIndicated from "../../components/form-correspondent/CorrespondentIndicated.vue";
import MailField from "@/components/comum/MailField";

// Apis
import apiCorrespondent from "@/api/correspondent/correspondent-api";
import apiCorrespondentPersonType from "@/api/correspondent/correspondent-person-type-api";
import apiUser from "@/api/user/user-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";

// Models
import CorrespondentModel from "@/model/correspondent-model";
import CorrespondentPersonTypeModel from "@/model/correspondent-person-type-model";
import UserModel from "@/model/user-model";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import storage from "@/utils/storage.js";
import utils from "@/utils/general.js";

export default {
  name: "FormCorrespondent",
  mixins: [
    mixinMessage,
    mixinGeral,
    mixinValidationRules,
    mixinAuthorization,
  ],
  components: {
    CorrespondentCover,
    CorrespondentFinancial,
    CorrespondentContactEmail,
    CorrespondentAdministrative,
    CorrespondentPartnersShareholders,
    CorrespondentSpreads,
    MailField,
    CorrespondentIndicated,
  },
  props: {
    isIndicated: { type: Boolean, default: false },
    indicatorId: { type: [Number, String] },
    isUpdate: { type: Boolean, default: false },
    updateId: { type: [Number, String] },
    aferSaveCallback: { type: Function, default: () => { } },
    cancelCallback: { type: Function, default: () => { } },
  },
  data() {
    return {
      loading: false,
      editMode: false,
      correspondent: new CorrespondentModel(),
      externalAcess: false,
      userDialog: false,
      user: new UserModel(),
      userFormValid: false,
      passwordVisible: false,
      listOfCorrespondentPersonTypes: [],
    };
  },
  mounted() {
    this.loading = true;
    if (this.isIndicated) {
      if (this.isUpdate) {
        this.getCorrespondentById(this.updateId);
        this.editMode = true;
      } else {
        this.getCorrespondentPersonType();
        this.editMode = false;
      }
    } else if (this.$route.params.id) {
      this.getCorrespondentById(this.$route.params.id);
      this.editMode = true;
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_update_form",
      });
    } else if (this.$route.params.token) {
      storage.saveTokenOnStorage(this.$route.params.token);
      let correspondent = utils.parseJwt(this.$route.params.token);
      this.getCorrespondentById(correspondent.id);
      this.editMode = true;
      this.externalAcess = true;
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_external_acces",
      });
    } else {
      this.editMode = false;
      this.getCorrespondentPersonType();
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_create_form",
      });
    }
  },

  computed: {
    isInternationalAgent() {
      return this.correspondent.correspondentPersonType.id == 3;
    },
    isPj() {
      return this.correspondent.correspondentPersonType.id == 2;
    },
  },

  methods: {
    getCorrespondentById(id) {
      apiCorrespondent
        .findId(id)
        .then((response) => {
          this.correspondent = new CorrespondentModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getCorrespondentPersonType() {
      apiCorrespondentPersonType
        .findAll()
        .then((response) => {
          this.listOfCorrespondentPersonTypes = response.data.map((c) => new CorrespondentPersonTypeModel(c));
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    save() {
      mixpanel.track("click", {
        button_name: "create_correspondent",
      });
      this.createCorrespondent();
    },
    update() {
      mixpanel.track("click", {
        button_name: "update_correspondent",
      });
      if (this.externalAcess) {
        this.createUser();
        return;
      }
      this.updateCorrespondent();
    },
    cancelCorrespondentCreation() {
      if (this.isIndicated) {
        this.cancelCallback();
        return;
      }
      mixpanel.track("click", {
        button_name: "cancel_correspondent_creation",
      });
      this.$router.push({
        name: "CorrespondentsControl",
      });
    },
    createCorrespondent() {
      if (this.isIndicated) {
        apiCorrespondent
          .addIndicated(this.indicatorId, this.correspondent)
          .then((response) => {
            this.sendMessage("Salvo com sucesso!", "success");
            let newCorrespondent = new CorrespondentModel(response.data);
            this.aferSaveCallback(newCorrespondent);
          })
          .catch((error) => {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
            );
          });

        return;
      }

      apiCorrespondent
        .add(this.correspondent)
        .then(() => {
          this.sendMessage("Correspondente salvo com sucesso!", "success");

          this.$router.push({
            name: "CorrespondentsControl",
          });
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    updateCorrespondent() {
      apiCorrespondent
        .update(this.correspondent)
        .then(() => {
          this.sendMessage("Salvo com sucesso!", "success");

          if (this.isIndicated) {
            this.aferSaveCallback(this.correspondent);
            return;
          }

          if (this.externalAcess) {
            storage.removeAuth();
            this.$router.push({
              name: "Login",
            });
          } else {
            this.$router.push({
              name: "CorrespondentsControl",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    createUser() {
      this.user = new UserModel({
        name: this.correspondent.name,
        email: this.correspondent.accessEmail,
        profileId: 2,
        correspondentId: this.correspondent.id
      });
      this.userDialog = true;
    },
    saveUser() {
      apiUser
        .add(this.user)
        .then(() => {
          this.sendMessage("Usuário cadastrado com sucesso!", "success");
          this.updateCorrespondent();
        })
        .catch((erro) => {
          this.sendMessage(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
        });
    }
  },
};
</script>
