<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Dados Cadastrais</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" cols="12" md="2" v-if="isInternationalAgent">
            <v-text-field outlined dense label="Documento de Identificação Internacional"
              v-model="correspondent.cpfCnpj"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="2" v-if="!isInternationalAgent">
            <CpfOrCnpjField v-model="correspondent.cpfCnpj" :isPJ="isPj" />
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field outlined dense label="Nome" v-model="correspondent.name"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <MailField label="Email de Acesso" v-model="correspondent.accessEmail"></MailField>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <PhoneField isLandline v-model="correspondent.landline" label="Telefone Fixo" />
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <PhoneField v-model="correspondent.cellphone" label="Celular" />
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <AddressComponent v-model="correspondent" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField";
import AddressComponent from "@/components/comum/AddressComponent";
import PhoneField from "@/components/comum/PhoneField.vue";
import MailField from "@/components/comum/MailField";

export default {
  name: "CorrespondentCover",
  components: {
    CpfOrCnpjField,
    AddressComponent,
    PhoneField,
    MailField,
  },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: { type: Object, },
    isInternationalAgent: { type: Boolean, },
    isPj: { type: Boolean, },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style></style>
