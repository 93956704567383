<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <v-tabs v-model="tab">
          <v-tab key="dadosCadastrais">
            <span>Dados Cadastrais</span>
          </v-tab>
          <v-tab key="informacoesBancarias">
            <span>Informações Bancárias</span>
          </v-tab>
          <v-tab key="documentos">
            <span>Documentos</span>
          </v-tab>
          <v-tab key="bancosCorretoras">
            <span>Bancos/Corretoras</span>
          </v-tab>
          <v-tab key="spreads">
            <span>Spreads</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item eager key="dadosCadastrais">
            <v-card>
              <CustomerCover :isPF="isPF" :isExchange="isExchange" :isBasicRemitance="isBasicRemittance"
                :isCompleteRemittance="isCompleteRemittance" v-model="customer" />
              <CustomerContact v-model="customer" />
              <CustomerAddress :isPF="isPF" :isExchange="isExchange" v-model="customer" />
              <CustomerWork v-if="isCompleteRemittance" v-model="customer" />
              <CustomerPartnersShareholders v-model="customer" />
              <CustomerDirectorsProxies v-model="customer" />
              <CustomerFinancial v-model="customer" :isExchange="isExchange" :isLegalPerson="isLegalPerson"
                :isRemitance="isRemittance" :isCompleteRemittance="isCompleteRemittance" />
              <CustomerPoliticallyExposedPerson v-model="customer" />
              <CustomerCompliance v-if="isLegalPerson" v-model="customer" />
              <CustomerRegistrationResponsible v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item eager key="informacoesBancarias">
            <v-card>
              <CustomerInternationalAccount v-if="isRemittance" v-model="customer" />
              <CustomerPaymentAccounts v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item eager key="documentos">
            <v-card>
              <CustomerDocuments :isLegalPerson="isLegalPerson" :isExchange="isExchange"
                :isBasicRemitance="isBasicRemittance" :isCompleteRemittance="isCompleteRemittance" v-model="customer" />
              <CustomerOperationalDocuments v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item eager key="bancosCorretoras">
            <v-card>
              <CustomerExchangeBanks v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item eager key="spreads">
            <v-card>
              <CustomerSpreads v-model="customer" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerCover from "@/components/form-customer/CustomerCover";
import CustomerContact from "@/components/form-customer/CustomerContact";
import CustomerAddress from "@/components/form-customer/CustomerAddress";
import CustomerPartnersShareholders from "@/components/form-customer/CustomerPartnersShareholders.vue";
import CustomerDirectorsProxies from "@/components/form-customer/CustomerDirectorsProxies.vue";
import CustomerFinancial from "@/components/form-customer/CustomerFinancial.vue";
import CustomerWork from "@/components/form-customer/CustomerWork.vue";
import CustomerPoliticallyExposedPerson from "@/components/form-customer/CustomerPoliticallyExposedPerson.vue";
import CustomerCompliance from "@/components/form-customer/CustomerCompliance.vue";
import CustomerRegistrationResponsible from "@/components/form-customer/CustomerRegistrationResponsible.vue";
import CustomerInternationalAccount from "@/components/form-customer/CustomerInternationalAccount.vue";
import CustomerDocuments from "@/components/form-customer/CustomerDocuments.vue";
import CustomerExchangeBanks from "@/components/form-customer/CustomerExchangeBanks.vue";
import CustomerSpreads from "@/components/form-customer/CustomerSpreads.vue";
import CustomerOperationalDocuments from "@/components/form-customer/CustomerOperationalDocuments.vue";
import CustomerPaymentAccounts from "../../components/form-customer/CustomerPaymentAccounts.vue";

export default {
  name: "CustomerFormTab",
  components: {
    CustomerCover,
    CustomerContact,
    CustomerAddress,
    CustomerPartnersShareholders,
    CustomerDirectorsProxies,
    CustomerFinancial,
    CustomerWork,
    CustomerPoliticallyExposedPerson,
    CustomerCompliance,
    CustomerRegistrationResponsible,
    CustomerInternationalAccount,
    CustomerDocuments,
    CustomerOperationalDocuments,
    CustomerExchangeBanks,
    CustomerSpreads,
    CustomerPaymentAccounts,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isLegalPerson: {
      type: Boolean,
    },
    isPF: {
      type: Boolean,
    },
    isExchange: {
      type: Boolean,
    },
    isRemittance: {
      type: Boolean,
    },
    isBasicRemittance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },

  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {},
  data() {
    return {
      customer: this.customerProp,
      tab: null,
    };
  },
};
</script>

<style></style>
