import api from '../api';

function add(bank) {
  bank = bank || {};
  return new Promise((resolve, reject) => {
    return api.post('/exchange-banks', bank)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(bank) {
  bank = bank || {};
  return new Promise((resolve, reject) => {
    return api.put(`/exchange-banks/${bank.id}`, bank)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/exchange-banks')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-banks/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
};