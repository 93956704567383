<template>
  <v-card class="box card-cambio">
    <h1>Resumo</h1>
    <v-divider></v-divider>
    <v-spacer class="py-2"></v-spacer>
    <OperationPreviewExchange v-if="operation.exchangeType.id == 2" v-model="operation" />
    <OperationPreviewRemittance v-else v-model="operation" />
  </v-card>
</template>

<script>
// Components
import OperationPreviewExchange from "@/components/form-operation/OperationPreviewExchange.vue";
import OperationPreviewRemittance from "@/components/form-operation/OperationPreviewRemittance.vue";

export default {
  name: "OperationPreview",
  mixins: [],
  components: { OperationPreviewExchange, OperationPreviewRemittance },
  data() {
    return {
      operation: this.operationProp,
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
  },
  mounted() {
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style></style>
