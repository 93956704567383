<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Dados Cadastrais</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" cols="12" md="2">
            <v-radio-group row class="py-0" v-model="bank.active">
              <v-radio :value="true" :label="'Ativo'"></v-radio>
              <v-radio :value="false" :label="'Inativo'"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <v-autocomplete label="Tipos de operação" :items="bankTypes" item-text="description" item-value="id"
              v-model="bank.exchangeType" return-object clearable dense outlined v-disabled-icon-focus>
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" cols="12" md="2">
            <CpfOrCnpjField isPJ v-model="bank.cnpj" />
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field outlined dense label="Razão Social" v-model="bank.companyName"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="3">
            <v-text-field outlined dense label="Nome Fantasia" v-model="bank.fantasyName"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <AddressComponent v-model="bank" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Apis
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";

// Models
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";

// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField";
import AddressComponent from "@/components/comum/AddressComponent";

export default {
  name: "BankCover",
  components: {
    CpfOrCnpjField,
    AddressComponent,
  },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
      bankTypes: [],
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
  },
  mounted() {
    this.getBankTypes();
  },
  methods: {
    getBankTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.bankTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style></style>
