var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":_vm.id}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({
      decimal: _vm.separadorDecimal,
      thousands: _vm.separadorMilhar,
      prefix: _vm.prefixo,
      precision: _vm.precisaoDecimal,
      suffix: _vm.sufixo
    }),expression:"{\n      decimal: separadorDecimal,\n      thousands: separadorMilhar,\n      prefix: prefixo,\n      precision: precisaoDecimal,\n      suffix: sufixo\n    }"}],ref:"campoTexto",attrs:{"id":'textfield-valor-'+_vm.id,"readonly":_vm.readonly,"label":_vm.label,"maxlength":_vm.maxlength,"rules":_vm.rules,"autofocus":_vm.autoFocus,"error-messages":_vm.mensagemDeErro,"outlined":_vm.outlined,"dense":_vm.dense,"suffix":_vm.suffix},on:{"change":_vm.emitirAlteracoes,"keydown":_vm.verificarSinal},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }