import api from '../api';

function add(store, bankId) {
  store = store || {};
  return new Promise((resolve, reject) => {
    return api.post(`/exchange-banks/${bankId}/exchange-stores`, store)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(store, bankId) {
  store = store || {};
  return new Promise((resolve, reject) => {
    return api.put(`/exchange-banks/${bankId}/exchange-stores/${store.id}`, store)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findByBank(bankId) {
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-banks/${bankId}/exchange-stores`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(storeId, bankId) {
  storeId = storeId || {};
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-banks/${bankId}/exchange-stores/${storeId}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findByBank,
  findId,
  add,
  update,
};