<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="box card-cambio">
        <h1>Emails de Contato</h1>
        <v-divider class="py-3"></v-divider>
        <v-row>
          <v-col class="py-0" cols="12" md="12">
            <h2>Cadastro</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Primeiro Email" v-model="bank.firstRegisterEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Segundo Email" v-model="bank.secondRegisterEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Terceiro Email" v-model="bank.thirdRegisterEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Operação</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Primeiro Email" v-model="bank.firstOperationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Segundo Email" v-model="bank.secondOperationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Terceiro Email" v-model="bank.thirdOperationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Documentos Operacionais</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Primeiro Email" v-model="bank.firstDocumentationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Segundo Email" v-model="bank.secondDocumentationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Terceiro Email" v-model="bank.thirdDocumentationEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>D.I. / D.U.E.</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Primeiro Email" v-model="bank.firstDiDueEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Segundo Email" v-model="bank.secondDiDueEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Terceiro Email" v-model="bank.thirdDiDueEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>

          <v-col class="py-0" cols="12" md="12">
            <h2>Financeiro</h2>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Primeiro Email" v-model="bank.firstFinancialEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Segundo Email" v-model="bank.secondFinancialEmail"></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-text-field outlined dense label="Terceiro Email" v-model="bank.thirdFinancialEmail"></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BankContactEmail",
  components: {},
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
  },
};
</script>

<style></style>
